
/* ----------------------------------------
    Imports
---------------------------------------- */

import { Component, Prop, Vue } from 'vue-property-decorator';
import ExternalSvg from '@svg/External.vue';

/* ----------------------------------------
    Component
---------------------------------------- */

@Component({
    props: {
        data: {
            type: Object,
            required: true
        },
        collection: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    components: {
        ExternalSvg
    },
    computed: {
        setImageSide() {
            if (this.isMobile()) {
              return 'left';
            }

            if (this.data.align) {
                if (this.data.align == 'left') {
                    return 'left';
                }
                if (this.data.align == 'right') {
                    return 'right';
                }
            }
            return 'left';
        }
    },
    methods: {
      isMobile() {
        let breakpoint = '960';
        if (window.innerWidth < breakpoint) {
          return true;
        }
        return false;
      }
    }
})

/* ----------------------------------------
    Export
---------------------------------------- */
export default class ImageTextBlock extends Vue {}
